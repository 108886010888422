import { Action, type ActionProps } from '@cvent/analytics/Action';

/**
 * Properties that will be tracked as part of this action.
 */
export interface BaseSessionCreatedProps extends ActionProps {
  /**
   * Type of fact
   */
  type: 'csn_search_session_base';
  /**
   * Device id
   */
  device_id: string | null;
  /**
   * Session id
   */
  session_id: string | null;
  /**
   * Environment
   */
  environment: string;
  /**
   * Abuse info header
   */
  abuseInfoHeader: string | null; // added by new-relic
  /**
   * User agent
   */
  user_agent: string | null;
  /**
   * Referrer
   */
  referrer: string | null;
  /**
   * IP address
   */
  ip_address: string | null;
  /**
   * Browser name
   */
  browser_name: string | 'UNKNOWN';
  /**
   * Browser version
   */
  browser_version: string | 'UNKNOWN';
  /**
   * Device OS
   */
  device_os: string | 'UNKNOWN';
  /**
   * Device type
   */
  device_type: string | 'UNKNOWN';
  /**
   * URL
   */
  url: string;
  /**
   * User id
   */
  user_id: string | null;
  /**
   * Experiments
   */
  experiments: string;
}

/**
 * @description This action tracks the creation of a new session /device id cookie from search app
 * @trigger This action is triggered when a new session is created in the search app (when a new device id cookie is created)
 */
export class BaseSessionCreated extends Action<BaseSessionCreatedProps> {
  id = 'baseSession.created';
}

export default BaseSessionCreated;
