'use client';

import { useEffect } from 'react';
import useAnalytics from '@/hooks/useAnalytics';
import { getPageUrl } from '@/utils/analyticsClientHelper';
import type { BaseSessionFact } from '@/analytics/models/cventAnalytics/BaseSessionFact';
import cventAnalytics from '@cvent/analytics';
import BaseSessionCreated from '@/analytics/actions/BaseSessionCreated';

type Props = {
  data: Omit<BaseSessionFact, 'url'>;
};

export default function LogBaseSessionFact({ data }: Props): JSX.Element {
  const analytics = useAnalytics();

  // don't spread base properties for this fact - https://cvent.slack.com/archives/C0635QK89CJ/p1707140496632329?thread_ts=1706885598.000029&cid=C0635QK89CJ
  useEffect(() => {
    analytics.reportFact<BaseSessionFact>({ ...data, url: getPageUrl() }, null, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  useEffect(() => {
    // cvent/analytics
    cventAnalytics.track(new BaseSessionCreated({ ...data, url: getPageUrl() }));
  }, [data]);

  return <></>;
}
