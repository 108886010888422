'use client';

import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { initRum } from '@cvent/nextjs/datadog/initRum';
import Script from 'next/script';
import { datadogOptions } from '@/config/getConfig';

type Props = {
  isProd: boolean;
};

export default function DatadogRum({ isProd }: Props): JSX.Element {
  return (
    <Script
      id="DD_RUM_SCRIPT"
      src="data:text/javascript," // This is a no-op, but is needed for the onload handler to actually execute
      strategy="lazyOnload"
      onLoad={() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (
          typeof window !== 'undefined' &&
          window.publicRuntimeConfig &&
          window.publicRuntimeConfig.NODE_ENVIRONMENT === 'production'
        ) {
          initRum({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...datadogOptions,
            sessionSampleRate: 100,
            sessionReplaySampleRate: isProd ? 25 : 5,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
            trackSessionAcrossSubdomains: true,
            useSecureSessionCookie: true,
            useCrossSiteSessionCookie: true,
            traceSampleRate: isProd ? 25 : 5,
            allowedTracingUrls: [window.location.origin],
            traceContextInjection: 'sampled',
            excludedActivityUrls: [
              // analytics
              /batch_facts/,
              /analytics/,
              /pendo.io/,
              /datadoghq.com/,
              // Video
              /wistia.com/,
              // Others
              /salesforceliveagent.com/
            ]
          });
        }
      }}
    />
  );
}
