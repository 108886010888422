'use client';

import { useEffect } from 'react';
import cventAnalytics from '@cvent/analytics';
import { UserTraits } from '@cvent/analytics/types';
import { BaseFactDetails } from '@/analytics/models/cventAnalytics/BaseFact';

/**
 * Sets global context for cvent/analytics and
 * if logged in -> will call identify - https://framework.docs.cvent.org/docs/packages/analytics/README#identify-users
 */
export function UpdateAnalyticsProperties({
  baseProperties,
  userTraits
}: {
  baseProperties: BaseFactDetails;
  userTraits: UserTraits;
}) {
  useEffect(() => {
    cventAnalytics.identify(userTraits);
  }, [userTraits]);

  useEffect(() => {
    cventAnalytics.updateGlobalActionProperties(baseProperties as Record<string, string>);
  }, [baseProperties]);

  return <></>;
}
