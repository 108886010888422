'use client';

import Script from 'next/script';
import useSiteCatalyst from '@/hooks/useSiteCatalyst';

type Props = {
  siteCatalystUrl: string;
  siteCatalystReportingSuite: string;
};

export default function SiteCatalyst({ siteCatalystUrl, siteCatalystReportingSuite }: Props): JSX.Element {
  const siteCat = useSiteCatalyst();
  const { isSiteCatLoaded, setIsSiteCatLoaded } = siteCat;
  return !isSiteCatLoaded ? (
    <Script
      id="site-catalyst"
      defer
      src={siteCatalystUrl}
      strategy="lazyOnload"
      onLoad={() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window && window.s) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.s_account = siteCatalystReportingSuite;
          setIsSiteCatLoaded(true);
        }
      }}
    />
  ) : (
    <></>
  );
}
