'use client';

import analytics from '@cvent/analytics';
import getConfig from '@/config/getConfig';

const { publicRuntimeConfig } = getConfig();

function initAnalytics() {
  if (typeof window !== 'undefined' && window.publicRuntimeConfig) {
    analytics.init({
      customerDataPlatform: {
        writeKey: publicRuntimeConfig.DATA_PLATFORM_WRITE_KEY,
        dataPlaneUrl: publicRuntimeConfig.DATA_PLATFORM_URL
      },
      cventAnalytics: {
        url: publicRuntimeConfig.CVENT_ANALYTICS_SERVICE
      },
      globalActionProperties: {
        appVersion: publicRuntimeConfig.CLIENT_VERSION,
        businessUnit: publicRuntimeConfig.BUSINESS_UNIT,
        // TODO: check why its not able to read from env
        product: 'search-and-advertising',
        component: publicRuntimeConfig.COMPONENT
      },
      pageTracking: true,
      disabled: process.env.NODE_ENV === 'development'
    });
  }
}

initAnalytics();
